<template>
  <div class="home-family-container">
    <qb-title title="《泉邦标杆案例》" complement="Benchmark case"></qb-title>

    <div class="content">
      <div class="grid" id="grid">
        <div v-for="(item, index) in 48" :key="index">
          <photo-card
            :img="require(`@/assets/images/case-logo/picture_${index + 1}.png`)"
            link="#"
          ></photo-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PhotoCard from './photo-card.vue'
export default {
  components: {
    PhotoCard
  }
}
</script>
<style lang="less" scoped>
.content {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  font-family: 'Source Sans Pro', Helvetica, sans-serif;
  font-weight: 300;
}

#grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 150px);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1920px;
}
</style>
